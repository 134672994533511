<template>
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="8.61798"
      cy="7.6883"
      r="6.7883"
      stroke="currentColor"
      stroke-width="1.8"
    />
    <path
      d="M16.632 19.0186C16.9451 19.4047 17.5119 19.4638 17.898 19.1507C18.284 18.8376 18.3431 18.2708 18.03 17.8848L16.632 19.0186ZM12.4748 13.8931L16.632 19.0186L18.03 17.8848L13.8728 12.7592L12.4748 13.8931Z"
      fill="currentColor"
    />
  </svg>
</template>
